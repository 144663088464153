<template>
  <v-container class="mt-n6 pt-0" id="settings" fluid tag="section">
    <customerManagement />
    <div style="width: 100%; height: 25px"></div>
    <identityManagement />
  </v-container>
</template>

<script>
export default {
  components: {
    customerManagement: () => import("@/components/general/customerManagement"),
    identityManagement: () => import("@/components/general/identityManagement"),
  },
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {},
};
</script>
